import MapboxDraw, { DrawActionableState } from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';

const constants = {
  geojsonTypes: {
    FEATURE: 'Feature',
    POLYGON: 'Polygon',
  },
  cursors: {
    ADD: 'add',
  },
  types: {
    POLYGON: 'polygon',
  },
  modes: {
    DRAW_POLYGON: 'draw_polygon',
  },
};

const FreehandPolygonMode: any = MapboxDraw.modes.draw_polygon;

FreehandPolygonMode.onSetup = function() {
  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [ [] ]
    }
  });

  this.addFeature(polygon);
  this.clearSelectedFeatures();
  this.updateUIClasses({ mouse: constants.cursors.ADD });
  this.activateUIButton(constants.types.POLYGON);
  this.setActionableState({
    trash: true
  } as DrawActionableState);

  return {
    polygon,
    currentVertexPosition: 0,
    dragMoving: false
  };
};

FreehandPolygonMode.onDrag = function (state, e) {
  state.dragMoving = true;
  this.updateUIClasses({ mouse: constants.cursors.ADD });
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
};

FreehandPolygonMode.onMouseUp = function (state) {
  if (state.dragMoving) {
    const unsimplifiedPolygon = state.polygon;
    try {
      // Simplify throws an error if the user draws a very small polygon
      this.simplify(state.polygon);
    }
    catch {
      state.polygon = unsimplifiedPolygon;
    }
    this.changeMode(constants.modes.DRAW_POLYGON, { featureIds: [ state.polygon.id ] });
  }
};

FreehandPolygonMode.onMouseDown = function () {
  if (!this.map || !this.map.dragPan) return;
  this.map.dragPan.disable();
};

FreehandPolygonMode.onClick = function() {};

FreehandPolygonMode.simplify = function(polygon) {
  // 1/1.05^(10x) Does a good job of not oversimplifying when zoomed in and not under simplifying when zoomed out
  const tolerance = 1 / Math.pow(1.05, 10 * this.map.getZoom());
  turf.simplify(polygon, {
    mutate: true,
    tolerance: tolerance,
    highQuality: true
  });
};

export default FreehandPolygonMode;
