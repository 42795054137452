import React, { useEffect, useState, type FC, useCallback } from 'react';
import { GeoFeatureAddressFavorite, PropertiesMapProps, PropertyDataProps } from '../../utility/types';
import Map, { Layer, Popup, Source } from 'react-map-gl';
import type { GeoJSONSource, MapRef, Point, PointLike } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import PropertyQuickViewModal from '../common/modals/PropertyQuickViewModal';
import '../../../assets/stylesheets/mapbox.css';
import DrawControl, { drawRef } from './draw-control';
import * as turf from '@turf/turf';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { getMapboxBoundingBox } from '../../utility/utility';
import { useFilteringContext } from '../../contexts/Filtering';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FreehandPolygonMode from './FreehandPolygon';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import Header from '../property-list-viewer/Header';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';
import * as layersUtil from './MapLayersUtil';
import { LoadingSpinner } from '../icons/OurIcons';

const PropertiesMap: FC<PropertiesMapProps> = ({ listSortId }) => {
  // 81px is how big the Header is by default, specifying the header height so it is more obvious why the map height is 719px
  return (
    <div className='h-[800px] w-full relative'>
      <div className='h-[81px] border-b border-gray-200 p-2'>
        <Header />
      </div>
      <PropertiesMapContainer listSortId={listSortId} />
    </div>
  );
};

const PropertiesMapContainer: FC<PropertiesMapProps> = ({ listSortId }) => {
  const popupWidth = 320;
  const popupWidthPixels = `${popupWidth}px`;
  const streetView = 'mapbox://styles/samreix/cm2lwl6ma000901nt9kk8eo7f';
  const satelliteView = 'mapbox://styles/samreix/cm2lx6csv005x01p97hov6ul4';
  const favorites = useSelector<RootState>(state => state.favorite.favorites);
  const mapKey = useSelector((state: RootState) => state.map.key);
  const mapButtonStyle = 'py-0.5 px-1 rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800';

  const {
    setTotalPropertiesCount,
    setPropertyDecisionCounts,
    setCriteriaMatchedPropertiesCount,
  } = usePropertyViewerContext();

  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
      setMapBoundingBox(getMapboxBoundingBox(node));
    }
  }, []);

  let mapDrawRef = drawRef;

  const { ransackObj, filteringDispatch } = useFilteringContext();

  const { propertiesData,
    propertyCounts,
    loadingProperties,
    pendingProperties,
    errorProperties } = useGetProperties(listSortId, ransackObj, 500, 1, null);

  useEffect(() => {
    if (propertiesData) {
      setTotalPropertiesCount(propertyCounts.total_property_records);
      setPropertyDecisionCounts({
        wantedPropertiesCount: propertyCounts.total_wanted_property_joins,
        unwantedPropertiesCount: propertyCounts.total_unwanted_property_joins,
        decidedPropertiesCount: propertyCounts.total_sorted_property,
        undecidedPropertiesCount: propertyCounts.total_unsorted_property,
      });
      setCriteriaMatchedPropertiesCount(propertyCounts.total_property_records_filtered);
    }
  }, [ propertiesData ]);

  const initialProperties: PropertyDataProps[] = propertiesData;

  // Calculate the map bounds from the properties or lasso lat and lon
  let bounds;
  let latitudes = [];
  let longitudes = [];
  if (initialProperties) {
    if (ransackObj?.polys) {
      latitudes = ransackObj.polys.map(poly =>
        poly.geometry.coordinates[0].map(coord => coord[1])
      ).flat();

      longitudes = ransackObj.polys.map(poly =>
        poly.geometry.coordinates[0].map(coord => coord[0])
      ).flat();
    }
    else {
      const validProperties = initialProperties.filter(property =>
        !!property.address.latitude && !!property.address.longitude && +property.address.latitude !== 0 && +property.address.longitude !== 0
      );

      latitudes = validProperties
        .map(property => property?.address?.latitude ? Number(property.address.latitude) : null)
        .filter(latitude => latitude !== null);

      longitudes = validProperties
        .map(property => property?.address?.longitude ? Number(property.address.longitude) : null)
        .filter(longitude => longitude !== null);
    }

    if (latitudes.length > 0 && longitudes.length > 0) {
      const sw = new mapboxgl.LngLat(Math.min(...longitudes), Math.min(...latitudes));
      const ne = new mapboxgl.LngLat(Math.max(...longitudes), Math.max(...latitudes));
      bounds = new mapboxgl.LngLatBounds(sw, ne);
    }
  }
  else {
    // Provide default coordinates if no valid latitudes and longitudes are found
    const defaultLatitude = 0;
    const defaultLongitude = 0;
    const sw = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    const ne = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    bounds = new mapboxgl.LngLatBounds(sw, ne);
  }

  const [ mapBoundingBox, setMapBoundingBox ] = useState(null);
  const [ popupInfo, setPopupInfo ] = useState(null);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ popupOffset, setPopupOffset ] = useState<PointLike>([ 0, 0 ]);
  const [ lassoSelections, setLassoSelections ] = useState({});
  const [ mapNode, setMapNode ] = useState(null);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ isLassoDrawMode, setIsLassoDrawMode ] = useState(false);
  const [ propertiesGeoJson, setPropertiesGeoJson ] = useState(null);
  const [ lassoGeoJson, setLassoGeoJson ] = useState(null);
  const [ isUserDrawnLasso, setIsUserDrawnLasso ] = useState(false);

  useEffect(() => {
    handleBoundsChange();
  }, [ initialProperties ]);

  // Create GeoJson for lasso tool layers
  useEffect(() => {
    let polygons = {};
    let isUserDrawn = false;

    if (ransackObj?.polys) {
      isUserDrawn = ransackObj.polys.every(poly => poly.properties.isUserDrawn);

      if (isUserDrawn) {
        polygons = ransackObj?.polys;
      }
    }

    const json = {
      type: 'FeatureCollection',
      features: polygons
    };
    setLassoGeoJson(json);
    setIsUserDrawnLasso(isUserDrawn);

    if (!!mapNode && !!mapDrawRef) {
      mapDrawRef.deleteAll();
    }
  }, [ ransackObj ]);

  // Process properties into GeoJSON format
  useEffect(() => {
    if (!propertiesData) return;

    const features = propertiesData.map(property => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ +property.address.longitude, +property.address.latitude ]
        },
        properties: {
          address: property.address,
          favorite: !!favorites[property.id]
        }
      } as GeoFeatureAddressFavorite;
    });

    const json = {
      type: 'FeatureCollection',
      features: features
    };

    setPropertiesGeoJson(json);
  }, [ propertiesData, favorites ]);

  // Handle setting lasso draw modes and saving drawn lassos to ransack
  useEffect(() => {
    if (!!mapNode && !!mapDrawRef) {
      // When you're done drawing
      if (!isLassoDrawMode) {
        mapDrawRef.changeMode('simple_select');
        // And there is a selection
        if (lassoSelections) {
          let lassoPolygons: turf.Feature<turf.Polygon>[] = [];

          for (const selection in lassoSelections) {
            lassoPolygons.push(turf.polygon(lassoSelections[selection].geometry.coordinates, { isUserDrawn: true }));
          }
          filteringDispatch({
            type: 'SET_LASSO',
            lasso: lassoPolygons
          });
        }
      }
      // When you start drawing
      else {
        mapDrawRef.changeMode('draw_polygon');
      }
    }
  }, [ isLassoDrawMode ]);

  const shouldShowLasso = () => Object.keys(lassoSelections).length !== 0 || isUserDrawnLasso;

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? streetView : satelliteView;
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  const toggleLassoDrawMode = () => {
    setIsLassoDrawMode(!isLassoDrawMode);
  };

  const clearLassoSelections = () => {
    if (mapDrawRef) {
      mapDrawRef.deleteAll();
      setLassoSelections({});

      // Prevents you from needing to click the map before being able to draw another lasso
      mapDrawRef.changeMode(mapDrawRef.getMode());
      if (!!ransackObj?.polys) {
        calculatePropertiesInView();
      }
    }
  };

  const calculatePropertiesInView = () => {
    if (!mapNode) {
      return [];
    }

    const bounds = mapNode.getBounds();

    const ne = bounds.getNorthEast();
    const nePoint = [ ne.lng, ne.lat ];
    const nw = bounds.getNorthWest();
    const nwPoint = [ nw.lng, nw.lat ];
    const se = bounds.getSouthEast();
    const sePoint = [ se.lng, se.lat ];
    const sw = bounds.getSouthWest();
    const swPoint = [ sw.lng, sw.lat ];

    const polygon = [ turf.polygon([ [ nePoint, nwPoint, swPoint, sePoint, nePoint ] ], { isUserDrawn: false }) ];

    filteringDispatch({
      type: 'SET_LASSO',
      lasso: polygon
    });
  };

  const handleBoundsChange = () => {
    if (!mapNode || !initialProperties || !initialProperties.length) {
      return;
    }
    if (!isUserDrawnLasso) {
      calculatePropertiesInView();
    }
  };

  const setPopupOrientationAndOffset = (point: Point) => {

    let orientation;
    let widthOffest;
    let heightOffset;

    if ((mapBoundingBox.bottom - mapBoundingBox.top) /2 > point.y) {
      orientation = 'top';
      heightOffset = 10;
    }
    else {
      orientation = 'bottom';
      heightOffset = -10;
    }

    // Get the horizontal offset to keep at least 5px between edge of popup and edge of map
    const halfOfWidthPlusPadding = popupWidth / 2 + 5;
    const leftOffset = halfOfWidthPlusPadding - point.x;
    const rightOffset = mapBoundingBox.right - halfOfWidthPlusPadding - point.x - mapBoundingBox.left;

    if (leftOffset > 0) {
      widthOffest = leftOffset;
    }
    else if (rightOffset < 0) {
      widthOffest = rightOffset;
    }
    else {
      widthOffest = 0;
    }

    const offset: PointLike = [ widthOffest, heightOffset ];

    setPopupOffset(offset);
    setPopupOrientation(orientation);
  };

  const handleMapClick = (e) => {

    e.originalEvent.stopPropagation();

    const features = e.features || [];
    if (features.length > 0) {
      const feature = features[0];

      // Cluster markers have a cluster_id, individual property markers do not
      // Handle cluster markers
      if (feature.properties.cluster_id) {
        const mapboxSource = mapNode.getMap().getSource(layersUtil.PROPERTIES) as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
          if (err) {
            return;
          }

          mapNode.getMap().easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });
        });
      }
      // Handle individual markers
      else {
        const address = JSON.parse(feature.properties.address);
        setPopupOrientationAndOffset(e.point);

        // Timeout prevents popupInfo being null upon clicking another marker when
        // the popup is open because closing the popup sets the popupInfo to null
        setTimeout(() => setPopupInfo(address), 0);
      }
    }
  };

  const onUpdate = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const mapLoader = () => {
    return (
      <div className='absolute w-full h-full z-10 bg-slate-500/20'>
        <div className='absolute w-[10%] h-[10%] top-[45%] right-[45%]'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </div>
    );
  };

  if (errorProperties) {
    return <div id='PropertiesViewApp-error'>Error: {errorProperties.message}</div>;
  }

  if (!propertiesGeoJson && (pendingProperties || loadingProperties)) {
    return (
      <div className='h-[719px] w-full relative'>
        {mapLoader()}
      </div>
    );
  }
  return (
    <>
      <div className='h-[719px] w-full relative'>
        <div className={`grid grid-cols-2 absolute w-full ${isLassoDrawMode ? 'z-[2] bg-slate-500/70' : ''}`}>
          {
            isLassoDrawMode &&
            < p className='m-2 col-start-1 self-center text-indigo-600 drop-shadow-[0px_0px_2px_white]'>
              {shouldShowLasso() ? 'Confirm Selection To Search' : 'Draw Your Selection'}
            </p>
          }
          <div className="col-start-2 justify-self-end self-center flex m-2 gap-2 z-[2]">
            <button className={mapButtonStyle}
              onClick={toggleLassoDrawMode}
            >
              {!isLassoDrawMode ? 'Lasso Select' : shouldShowLasso() ? 'Confirm Lasso' : 'Cancel Lasso'}
            </button>
            {shouldShowLasso() && <button className={mapButtonStyle} onClick={clearLassoSelections}>
              {'Clear selection'}
            </button>
            }
            <button className={mapButtonStyle} onClick={toggleSatelliteView}>
              {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
            </button>
          </div>
        </div>
        <Map
          ref={mapRef}
          mapboxAccessToken={mapKey}
          initialViewState={{
            bounds: bounds,
            fitBoundsOptions: { padding: 20 }
          }}
          mapStyle={streetView}
          onZoomEnd={handleBoundsChange}
          onDragEnd={handleBoundsChange}
          onRotateEnd={handleBoundsChange}
          onLoad={handleBoundsChange}
          onClick={handleMapClick}
          onMouseEnter={() => mapNode.getMap().getCanvas().style.cursor = 'pointer'}
          onMouseLeave={() => mapNode.getMap().getCanvas().style.cursor = ''}
          interactiveLayerIds={[ layersUtil.clusterLayer.id, layersUtil.unclusteredPointLayer.id, layersUtil.unclusteredFavoritePointLayer.id ]}
        >
          <Source
            id={layersUtil.LASSO}
            type='geojson'
            data={lassoGeoJson}
          >
            <Layer {...layersUtil.lassoFill} />
            <Layer {...layersUtil.lassoLine} />
          </Source>
          <Source
            id={layersUtil.PROPERTIES}
            type='geojson'
            data={propertiesGeoJson}
            cluster={true}
            clusterMaxZoom={12}
          >
            <Layer {...layersUtil.clusterLayer} />
            <Layer {...layersUtil.clusterCountLayer} />
            <Layer {...layersUtil.unclusteredFavoritePointLayer} />
            <Layer {...layersUtil.unclusteredPointLayer} />
          </Source>
          {popupInfo && (
            <Popup
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              maxWidth={popupWidthPixels}
              anchor={popupOrientation}
              offset={popupOffset}
              closeButton={false}
              closeOnMove={true}
              onClose={() => setPopupInfo(null)}
            >
              <PropertyQuickViewModal
                propertyId={popupInfo.property_id}
              />
            </Popup>
          )}
          <DrawControl
            position='top-left'
            displayControlsDefault={false}
            modes={Object.assign(MapboxDraw.modes, {
              draw_polygon: FreehandPolygonMode
            })}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Map>
      </div>
    </>
  );
};
export default PropertiesMap;
