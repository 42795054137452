// @ts-ignore
import { AnyLayout } from 'mapbox-gl';
import { FilterDescriptor, FilterValuesType } from './Filtering';

function convertCriteriaToFilterDescriptor(criteria: AnyLayout, filters: Record<string, FilterDescriptor>):
 {
  newActiveFilterIds: string[],
  newFilters: Record<string, FilterDescriptor>
 } {
  // for tests, get the output of this and compare the correct output!!!!!
  const newActiveFilterIds: string[] = [];

  let newFilters = { ...filters }; // Initialize with the current state of filters
  for (const filterId in criteria) {
    if (criteria[filterId] === null) {
      continue;
    }

    if (filterId.startsWith('max_')) {
      const key = filterId.substring(4);
      newActiveFilterIds.push(key);

      newFilters[key] = {
        ...newFilters[key],
        values: {
          ...newFilters[key]?.values,
          max: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }

    if (filterId.startsWith('min_')) {
      const key = filterId.substring(4);
      newActiveFilterIds.push(key);

      newFilters[key] = {
        ...filters[key],
        values: {
          ...newFilters[key].values,
          min: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }

    switch (filterId) {
    case 'title': {
      // Handle 'title' case (if needed)
      break;
    }

    case 'status':
    case 'pool':
    case 'data_source':
    case 'how_sold':
    case 'property_class':
    case 'recent_rehab':
    case 'sale_or_rent': {
      const options = filters[filterId]?.options;
      let newValues = [];

      for (const s in criteria[filterId]) {
        for (const option of options) {
          if (option.name === s) {
            newValues.push({ name: option.name, code: option.code });
          }
        }
      }

      if (newValues.length > 0) {
        newActiveFilterIds.push(filterId);
        newFilters[filterId] = {
          ...newFilters[filterId],
          values: {
            state: newValues,
          } as FilterValuesType,
        };
      }
      break;
    }

    case 'property_id':
    case 'clerk_number':
    case 'county_code':
    case 'street':
    case 'subdivision':
    case 'tax_id':
    case 'zillow_url':
    case 'last_mls_id':
    case 'fullAddress': {
      newActiveFilterIds.push(filterId);

      newFilters[filterId] = {
        ...newFilters[filterId],
        values: {
          query: criteria[filterId],
        } as FilterValuesType,
      };
      break;
    }

    default: {
      console.error('***not handling:', filterId);
      break;
    }
    }

  }

  const ret = { newActiveFilterIds, newFilters };
  return ret;
};

export { convertCriteriaToFilterDescriptor };

// todo write a test that makes sure all of these behave as expected!!!!!

