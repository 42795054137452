// FilteringContext.tsx
import React, { createContext, useContext, useState, useEffect, useReducer, useMemo } from 'react';
import TieredRangeSelectorComponent from '../components/propertySearch/Components/filters/TieredRangeSelectorComponent';
import DualValueRangeCard from '../components/propertySearch/Components/filters/DualValueRangeCard';
import MultiStateSelectorComponent from '../components/propertySearch/Components/filters/MultiStateSelectorComponent';
import RangeSliderComponent from '../components/common/RangeSliderComponent';
import BooleanToggleComponent from '../components/propertySearch/Components/filters/BooleanToggleComponent';
import SearchStringComponent from '../components/propertySearch/Components/filters/SearchStringComponent';
import { getDefaultFilters } from '../api/SearchCriteria/getDefaultFilters';
import BooleanTriStateSelectorComponent from '../components/propertySearch/Components/filters/BooleanTriStateSelectorComponent';
import WantedMultiSelector from '../components/propertySearch/WantedMultiSelector';
import FavoriteTriStateSelector  from '../components/propertySearch/FavoriteTriStateSelector';
import { generateRansackObject } from './ransackGenerator';
import DateRangeSelector from '../components/propertySearch/DateRangeSelector';
import { WantedMultiSelectorProps, BooleanTriStateSelectorComponentProps, MultiStateSelectSelectorComponentProps, BooleanToggleComponentProps, DateRangeSelectorProps, FavoriteTriStateSelectorProps, RangeSelectorComponentProps, SearchStringComponentProps } from '../components/propertySearch/Components/filters/utility';
import { convertCriteriaToFilterDescriptor } from './convertCriteriaToFilterDescriptor';
import { TableColumn } from '../redux/state/columnsSlice';
import useSessionStorage from '../hooks/useSessionStorage';
import { areFiltersDefault, isFilterActive } from './filterUtils';
import { Feature, Polygon } from '@turf/turf';

export interface DateRangeSelectorValuesType {
  startDate: string;
  endDate: string;
}

export interface RangeValuesType {
  min: number;
  max: number;
}

export interface msOptionInterface {
  name: string;
  code: string;
  selectedLabelName?: string;
  valueString?: string;
}
export interface TieredRangeSelectorValuesType extends RangeValuesType {}
export interface RangeInputSelectorValuesType extends RangeValuesType {}
export interface RangeSliderSelectorValuesType extends RangeValuesType {}
export interface BooleanTriStateSelectorValuesType {  state: true | false | null; }
export interface MultiSelectorValuesType { state: msOptionInterface[]; }
export interface StringSelectorValuesType { query: string; }
export interface BooleanToggleValuesType { state: boolean; }

// Above are all of the possible "values" types that a filter can have
export type FilterValuesType = TieredRangeSelectorValuesType | RangeInputSelectorValuesType | RangeSliderSelectorValuesType | BooleanTriStateSelectorValuesType | MultiSelectorValuesType | StringSelectorValuesType | BooleanToggleValuesType | DateRangeSelectorValuesType;

export interface FilterMetadata {
  unit?: string;
  triStateFilterMeta?:
  {
    icons?: {                   // Customizable icons for different states
      active: React.ReactNode;
      inactive: React.ReactNode;
      indeterminate: React.ReactNode;
    }
  }
  multiSelectStateMeta?: {
    maxSelectedLabels: number;
    options: { name: string, code: string }[];
  }
}

export type FilterDescriptor = {
  options: any;
  id: string; // to identify the filter in sql
  title: string; // Title to display for the filter
  description?: string; // Optional description of the filter to display
  filter_type: keyof typeof FilterType ;
  values: FilterValuesType;
  defaultValues: FilterValuesType; // default values for this filter
  emptyValues: FilterValuesType; // empty values for this filter
  metadata: FilterMetadata;
  ransack_key: string;
  unit?: string;
  unit_type?: string;
};

// creates a mapping of string constants for the filter types
export const FilterType = {
  BooleanToggleFilterType: 'booleanToggle',
  StringSelectorFilterType: 'stringSelector',
  RangeInputSelectorFilterType: 'rangeInputSelector',
  TieredRangeSelectorFilterType: 'tieredRangeSelector',
  DateRangeSelectorFilterType: 'dateRangeSelector',
  MultiSelectorFilterType: 'multiSelector',

  RangeSliderSelectorFilterType: 'rangeSliderSelector',
  BooleanTriStateSelectorFilterType: 'booleanTriStateSelector',
  WantedMultiSelectorFilterType: 'wantedMultiSelector',
  FavoriteTriStateSelectorFilterType: 'favoriteTriStateSelector',
} as const;

// This registry maps each filter type (defined in FilterTypes) to a specific React component that is designed to handle that filter's UI
export const FilterComponentRegistry: Record<keyof typeof FilterType, React.ComponentType<any>> = {
  TieredRangeSelectorFilterType: TieredRangeSelectorComponent as React.ComponentType<RangeSelectorComponentProps>,
  RangeInputSelectorFilterType: DualValueRangeCard as React.ComponentType<RangeSelectorComponentProps>,
  RangeSliderSelectorFilterType: RangeSliderComponent as React.ComponentType<RangeSelectorComponentProps>,
  BooleanTriStateSelectorFilterType: BooleanTriStateSelectorComponent as React.ComponentType<BooleanTriStateSelectorComponentProps>,
  MultiSelectorFilterType: MultiStateSelectorComponent as React.ComponentType<MultiStateSelectSelectorComponentProps>,
  StringSelectorFilterType: SearchStringComponent as React.ComponentType<SearchStringComponentProps>,
  BooleanToggleFilterType: BooleanToggleComponent as React.ComponentType<BooleanToggleComponentProps>,
  WantedMultiSelectorFilterType: WantedMultiSelector as React.ComponentType<WantedMultiSelectorProps>,
  FavoriteTriStateSelectorFilterType: FavoriteTriStateSelector as React.ComponentType<FavoriteTriStateSelectorProps>,
  DateRangeSelectorFilterType: DateRangeSelector as React.ComponentType<DateRangeSelectorProps>,
};

/**
 *
 * The purpose of this context is to handle the filter parameters. When a filter is changed, this context handles the generation
 * of the search string in the property context so it can make the calls to get the updated properties from the db
 *
 */
const initialState = {
  filters: {}, // Initial empty state
  activeFilterIds: new Set<string>(),
  noFiltersSet: true,
  defaultFiltersSet: false,
  lasso: null
};

type Action =
  | { type: 'UPDATE_FILTER'; id: string; values: Partial<FilterValuesType> }
  | { type: 'LOAD_FILTERS_FROM_DB'; payload: Record<string, FilterDescriptor> }
  | { type: 'SET_INITIAL_FILTERS_FROM_SESSION'; payload: Record<string, FilterDescriptor> }
  | { type: 'RESET_FILTERS' }
  | { type: 'DEFAULT_FILTERS' }
  | { type: 'LOAD_CRITERIA_FROM_SAVED'; criteria: Record<string, any> }
  | { type: 'SET_LASSO'; lasso: Feature<Polygon>[] }

// Define the state type
interface State {
  filters: Record<string, FilterDescriptor>;
  activeFilterIds: Set<string>;
  noFiltersSet: boolean;
  defaultFiltersSet: boolean;
  lasso: Feature<Polygon>[];
}

// Reducer function to handle state changes
function filterReducer(state: State, action: Action): State {
  switch (action.type) {
  case 'LOAD_FILTERS_FROM_DB': {
    const activeFilterIds = new Set<string>(
      Object.entries(action.payload).filter(([ _, filter ]) => isFilterActive(filter)).map(([ id ]) => id)
    );
    return {
      ...state,
      filters: { ...state.filters, ...action.payload },
      activeFilterIds,
      noFiltersSet: activeFilterIds.size === 0,
      defaultFiltersSet: areFiltersDefault({ ...state.filters, ...action.payload }),
    };
  }

  case 'LOAD_CRITERIA_FROM_SAVED': {
    let nextFilters = Object.keys(state.filters).reduce((acc, filterId) => {
      acc[filterId] = {
        ...state.filters[filterId],
        values: state.filters[filterId].defaultValues,
      };
      return acc;
    }, {} as Record<string, FilterDescriptor>);

    const { newFilters, newActiveFilterIds } = convertCriteriaToFilterDescriptor(action.criteria, nextFilters);
    const activeFilterIds = new Set(newActiveFilterIds);

    return {
      ...state,
      filters: newFilters,
      activeFilterIds,
      noFiltersSet: activeFilterIds.size === 0,
      defaultFiltersSet: areFiltersDefault(newFilters),
    };
  }

  case 'SET_INITIAL_FILTERS_FROM_SESSION': {
    const activeFilterIds = new Set<string>(
      Object.entries(action.payload).filter(([ _, filter ]) => isFilterActive(filter)).map(([ id ]) => id)
    );

    return {
      ...state,
      filters: { ...state.filters, ...action.payload },
      activeFilterIds,
      noFiltersSet: activeFilterIds.size === 0,
      defaultFiltersSet: areFiltersDefault({ ...state.filters, ...action.payload }),
    };
  }

  case 'UPDATE_FILTER': {
    if (!state.filters[action.id]) return state;

    const updatedFilter = {
      ...state.filters[action.id],
      values: { ...state.filters[action.id].values, ...action.values },
    };

    const isActive = isFilterActive(updatedFilter);
    const newActiveFilterIds = new Set(state.activeFilterIds);
    isActive ? newActiveFilterIds.add(action.id) : newActiveFilterIds.delete(action.id);

    const updatedFilters = {
      ...state.filters,
      [action.id]: updatedFilter,
    };

    return {
      ...state,
      filters: updatedFilters,
      activeFilterIds: newActiveFilterIds,
      noFiltersSet: newActiveFilterIds.size === 0,
      defaultFiltersSet: areFiltersDefault(updatedFilters),
    };
  }

  case 'RESET_FILTERS': {
    const resetFilters = Object.keys(state.filters).reduce((acc, filterId) => {
      acc[filterId] = {
        ...state.filters[filterId],
        values: state.filters[filterId].emptyValues,
      };
      return acc;
    }, {} as Record<string, FilterDescriptor>);
    return {
      ...state,
      filters: resetFilters,
      activeFilterIds: new Set(),
      noFiltersSet: true,
      defaultFiltersSet: false,
    };
  }

  case 'DEFAULT_FILTERS': {
    const activeFilterIds = new Set<string>();
    const defaultFilters = Object.keys(state.filters).reduce((acc, filterId) => {
      const filter = state.filters[filterId];
      const shouldSetToDefault = JSON.stringify(filter.defaultValues) !== JSON.stringify(filter.emptyValues);

      if (shouldSetToDefault) {
        activeFilterIds.add(filterId);
      }

      acc[filterId] = {
        ...filter,
        values: shouldSetToDefault ? filter.defaultValues : filter.emptyValues
      };
      return acc;
    }, {} as Record<string, FilterDescriptor>);

    return {
      ...state,
      filters: defaultFilters,
      activeFilterIds,
      noFiltersSet: activeFilterIds.size === 0,
      defaultFiltersSet: areFiltersDefault(defaultFilters),
    };
  }

  case 'SET_LASSO': {
    return {
      ...state,
      lasso: action.lasso
    };
  }

  default:
    throw new Error('Unhandled action type');
  }
}

type SortingType = { field: string; direction: 'asc' | 'desc'| null } | null;
const FilteringContext = createContext<{
  state: State;
  filteringDispatch: React.Dispatch<Action>
  isFilteringActive: boolean;
  appliedFiltersString: string;
  ransackObj: any;
  handleSort: (col: TableColumn | null | SortingType) => void;
  sorting: SortingType;
  activeFilters: Set<string>;
    } | undefined>(undefined);

// Context Provider Component
export const FilteringProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ state, filteringDispatch ] = useReducer(filterReducer, initialState);
  const [ ransackObj, setRansackObj ] = useState<any>({ state: 'unset' });
  const [ initialFiltersRetrievedFromDB, setInitialFiltersRetrievedFromDB ] = useState(false);
  const { storedValue: sorting, setValue: setSorting, clearStorageKey: clearSorting } = useSessionStorage('sorting', null);
  const { storedValue: filters, setValue: setFilters, clearStorageKey: clearFilters } = useSessionStorage('table-filter-settings-storage', null);

  useEffect(() => {
    const fetchFilters = async () => {
      const initialFilters = await getDefaultFilters();
      filteringDispatch({ type: 'LOAD_FILTERS_FROM_DB', payload: initialFilters });

      setInitialFiltersRetrievedFromDB(true);
    };

    fetchFilters();
    // this is only getting the fitlers from the session storage. Make db work in future
    if (filters && filters.length > 0) {
      const parsedState = JSON.parse(filters) as { filters: Record<string, FilterDescriptor> };
      if (parsedState.filters) {
        const filters = Object.values(parsedState.filters);
        const saveFilters: Record<string, FilterDescriptor> = filters?.reduce((acc: Record<string, FilterDescriptor>, filter: FilterDescriptor) => {
          if (JSON.stringify(filter.values) !== JSON.stringify(filter.defaultValues)) {
            acc[filter.id] = filter;
          }
          return acc;
        }, {} as Record<string, FilterDescriptor>);

        filteringDispatch({ type: 'SET_INITIAL_FILTERS_FROM_SESSION', payload: saveFilters });
      }
    }

    if (sorting) {
      handleSort(sorting);
    }
  }, []);

  useEffect(() => {
    if (state.filters) {
      setFilters(JSON.stringify({ filters: state.filters }));
    }
  }, [ state ]);

  const handleSort = (col: SortingType) => {
    if (!col || !col.field) {
      clearSorting();
      setSorting(null);
      return;
    }

    if (col.field === 'fullAddress') {
      return;
    }

    if (col.field === 'removeSort') {
      clearSorting();
      setSorting(null);
      return;
    }

    if (col.direction) {
      setSorting({ field: col.field, direction: col.direction });
      return;
    }
    // if we dont have a direction, we need to toggle the sorting
    if (sorting && sorting.field === col.field) {
      if (sorting.direction === 'asc') {
        setSorting({ field: col.field, direction: 'desc' });
      } else {
        clearSorting();
        setSorting(null);
      }
    } else {
      setSorting({ field: col.field, direction: 'asc' });
    }
  };

  const value = useMemo(() => {
    const isFilteringActive = Object.values(state.filters).some(filter => isFilterActive(filter as FilterDescriptor));
    const noFiltersSet = state.noFiltersSet;
    const defaultFiltersSet = state.defaultFiltersSet;

    let ransackLocal = {};
    if (initialFiltersRetrievedFromDB) {
      ransackLocal = generateRansackObject(state.filters, sorting, state.lasso);
      setRansackObj(ransackLocal);
    } else {
      ransackLocal = { state: 'unset' };
    }

    return {
      state,
      filteringDispatch,
      isFilteringActive,
      noFiltersSet,
      defaultFiltersSet,
      appliedFiltersString: isFilteringActive ? JSON.stringify(state.filters) : '',
      ransackObj: ransackLocal,
      handleSort,
      sorting,
      activeFilters: state.activeFilterIds,
    };
  }, [ state, sorting, initialFiltersRetrievedFromDB ]);

  return <FilteringContext.Provider value={value}>{children}</FilteringContext.Provider>;
};

export const useFilteringContext = () => {
  const context = useContext(FilteringContext);
  if (!context) {
    throw new Error('useFilteringContext must be used within a FilteringProvider');
  }
  return context;
};

